@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');
html, body, #root {
    height: 100vh;
  }

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
}

/* -change default outline focus to match the style of the rest of the site- */
*:focus {
    outline: none !important;
    border: 1px solid rgb(89, 89, 216);
   }

a{
    color:  rgb(89, 89, 216);
    text-decoration: none;
}
li:focus{
    border: none;
    color: rgb(89, 89, 216);
}
.App{
    position: relative;
    height: 100%;
    width: 90%;
    margin: 0 auto;

}
.App-header{
    width: 100%;
    margin-top: 2rem;
    letter-spacing: 1px;
}
.App-title{
    font-weight: 700;
}
.App-intro{
    color: rgb(89, 89, 216);
    font-size: 1rem;
}
.container{
    width: 100%;
    margin-top: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    height: 80%;
}
.sidebar{
    width:25%;
    height: 80vh;
    min-width: 150px;
    letter-spacing: 1px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
/* .sidebar li:focus{
    border: none;
} */
.searchbox{
    padding-right: 1rem;
    margin-bottom: 2rem;
}
.searchbox input {
    width: 100%;
    padding: 10px 10px 10px 5px;
    font-size: 1.2rem;
    outline: none;
}

ul{
    list-style: none;
    line-height: 3rem;
    font-size: 1.2rem;
}
ul li:hover{
    color: rgb(89, 89, 216);
    cursor: pointer;
}
.contact{
    font-size: 1.2rem;
    margin-top: auto;
}
.filter-options{
    display: none;
}
.api-error{
    color: red;
}

/*-------------------MAP--------------------*/

.map-container{
    width: 60%;
    height: 80vh;
    position: fixed;
    right: 4rem;
    top: 6.5rem;
}
.map{
    width: 100%;
    height: 100%;
    position: relative;
}
.marker{
    width: 30px;
}
.infoWindow{
    height: 25rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 550px !important;
}
.infoWindow-pictures{
    width: 100%;
}
.infoWindow-text{
    margin: 1rem 0;
}
.wiki-text{
    margin-bottom: 1rem;
}

.name{
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 1.2rem;
}
.picture{
    width: 100%;
    margin-bottom: 1rem;
}
.credits{
    margin: 1rem 0;
}
.google-error{
    width: 60%;
    /* margin: 0 auto; */
    /* text-align: center; */
    /* height: 80vh; */
    position: fixed;
    right: 0;
    top: 15rem;
    line-height: 2;
    color: red;

}



/*---------------MEDIA QUERIES---------------------------*/

@media only screen and (max-width: 700px){
    .App{
        width: 95%;
    }
    .App-header{
        margin-top: 1rem;
    }
    .container{
        display: flex;
    }
    .map-container{
        position: relative;
        height: 55vh;
        width: 100%;
        right: 0;
        top: 0;
      }
    .App-title{
        font-size: 1.9rem;
    }
    .App-intro{
        font-size: .9rem;
    }
    .searchbox{
        display: none;
    }
    .placeslist{
        margin-top: 1rem;
    }
    .infoWindow{
        height: 12rem;
        width: 250px !important;
    }
    .placeslist{
        display: none;
    }
    .sidebar{
        width:100%;
        min-width: 100%;
        letter-spacing: 1px;
        height: 12rem;
        margin-top: .5rem;
    }
    .filter-options{
        display: block;
        margin-top: 1rem;
    }
    .options{
        width: 100%;
        border: 1px solid rgb(89, 89, 216);
        border-radius: 0;
        padding: 5px 1rem;
        font-size: 1rem;
        -webkit-tap-highlight-color: rgb(89, 89, 216);
    }
    .options-container{
        background: white;
        border: 1px solid  rgb(89, 89, 216);
        -webkit-transform: translateY(-220px);
            -ms-transform: translateY(-220px);
                transform: translateY(-220px);
    }
    .option{
        letter-spacing: 2px;
        margin-left: 6px;
        margin: 1rem 1rem;
        z-index: 9999;
    }
    .contact-smartphone{
        font-size: 1.1rem;
        margin-top: 1rem;
    }
    .contact{
        display: none;
    }
    
    }